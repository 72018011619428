import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import {
  FlexRowCenterVertical,
  HeaderLogo as HeaderSmallLogo,
  DsiSearchForm as SearchForm,
  OutsideClick,
  DsiMobileNavMenu as MobileNavMenu,
  DsiSvgIcon as SvgIcon,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import HeaderCart from './HeaderCart';
import HeaderProfile from './HeaderProfile';
import { MobileWrapper } from '../_helpers/Default/ResponsiveWrappers';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import { MobileNavigation } from './MobileNavigation';
import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { HeaderFavourite } from './HeaderFavourite';
import { __ } from 'react-i18n';
import * as cookie from 'react-cookies';
import {
  DSI_VAT_VIEW_COOKIE,
  DSI_VOC_VIEW_COOKIE,
} from '../../containers/App/constants';
import { useState } from 'react';

interface Props {
  lang: string;
  items: ThenArg<typeof API.loadTree>;
  landingPages: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
  };
  toggleResponsiveCategoryMenu: any;
  searchTerm: string;
  handleSearchTermChange: (e: any) => void;
  redirectToSearchResultsPage: (e: any) => void;
  handleSearchFocus: any;
  hideSearchResultsViewer: () => void;
  resultsDropdownVisible: boolean;
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  clearSearchInput: () => void;
  byId: any;
  headerArticles: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  cartData: ThenArg<typeof API.getCart>;
  user: any;
  showActionLink: boolean;
  showGiftsCategory: boolean;
}

/*****************/

const StyledMobileWrapper = styled(MobileWrapper)`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  border-bottom: ${({ theme }) => theme.borders.primary};
  margin: 0 auto;
`;

const HeaderWrapper = styled(OutsideClick)`
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.textPrimary};
  margin: 0 auto;
  width: 100%;
  align-items: center;

  @media print {
    display: none;
  }
`;

const MobileMenuWrapper = styled(FlexRowCenterVertical)`
  width: 100%;
  padding: ${rem(16)};
  height: ${rem(80)};
  justify-content: space-between;
  align-items: end;
  border-bottom: ${({ theme }) => theme.borders.banners};
`;

const MenuSearchWrapper = styled(FlexRowCenterVertical)`
  justify-content: space-between;
  padding: ${rem(16)};
`;

const SearchWrapper = styled.div`
  width: 100%;
`;

const RightWrapper = styled(FlexRowCenterVertical)``;

const LeftWrapper = styled(RightWrapper)``;

const StyledSvgIcon = styled(SvgIcon)`
  fill: ${({ theme }) => theme.colors.textPrimary};
`;

function MobileHeader({
  searchTerm,
  handleSearchTermChange,
  redirectToSearchResultsPage,
  handleSearchFocus,
  hideSearchResultsViewer,
  resultsDropdownVisible,
  items,
  landingPages,
  partialSearchProducts,
  partialSearchCategories,
  partialSearchProductsIsFetching,
  partialSearchCategoriesIsFetching,
  clearSearchInput,
  lang,
  byId,
  headerArticles,
  cartData,
  dispatch,
  user,
  showActionLink,
  showGiftsCategory,
}: Props) {
  const [isSearchVisible, setIsSearchVisible] = React.useState(false);
  const [isMenuVisible, setIsMenuVisible] = React.useState(false);
  const searchWrapper = React.createRef<HTMLDivElement>();

  const logoSrc = '/images/icons/logo.svg';

  const isB2B = user && user.b2b;
  const [vocMoc, setVocMoc] = useState(
    cookie.load(DSI_VOC_VIEW_COOKIE) || 'voc',
  );
  const [vatView, setVatView] = useState(
    cookie.load(DSI_VAT_VIEW_COOKIE) || 'withVat',
  );
  const cookieProductVocView = cookie.load(DSI_VOC_VIEW_COOKIE);
  const cookieProductVatView = cookie.load(DSI_VAT_VIEW_COOKIE);
  React.useEffect(() => {
    if (cookieProductVocView) {
      setVocMoc(cookieProductVocView);
    }
    if (cookieProductVatView) {
      setVatView(cookieProductVatView);
    }
  }, [cookieProductVocView, cookieProductVatView]);
  const showMoc = isB2B ? vocMoc === 'moc' : true;
  const showWithVat = isB2B ? vatView === 'withVat' : true;

  React.useEffect(() => {
    if (!resultsDropdownVisible) {
      setIsSearchVisible(false);
    }
  }, [resultsDropdownVisible]);

  React.useEffect(() => {
    const node = searchWrapper.current;
    if (isSearchVisible && node) {
      const input = node?.querySelector('input');
      if (input) {
        input.focus();
      }
    }
  }, [isSearchVisible, searchWrapper]);

  const toggleMenu = () => {
    document.body.style.overflow = isMenuVisible ? 'visible' : 'hidden';
    setIsMenuVisible(!isMenuVisible);
  };

  const closeMenu = () => {
    document.body.style.overflow = 'visible';
    setIsMenuVisible(false);
  };

  return (
    <StyledMobileWrapper>
      <HeaderWrapper handleFunction={hideSearchResultsViewer}>
        <MobileMenuWrapper>
          <LeftWrapper>
            <HeaderSmallLogo logo={logoSrc} />
          </LeftWrapper>
          <RightWrapper>
            <HeaderProfile user={user} dispatch={dispatch} />
            <HeaderFavourite />
            <HeaderCart
              cartData={cartData}
              numberOfProducts={2}
              totalPrice={900.99}
              currency={'EUR'}
              cartWidth={50}
              isMobile={true}
              dispatch={dispatch}
              user={user}
            />
          </RightWrapper>
        </MobileMenuWrapper>
        {!isSearchVisible && !isMenuVisible && (
          <MenuSearchWrapper>
            <StyledSvgIcon
              icon={IconType.listMenu}
              alt={__('Otvoriť menu')}
              cursor={'pointer'}
              marginRight={24}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={toggleMenu}
            />
            <StyledSvgIcon
              icon={IconType.search}
              alt={__('Otvoriť vyhľadávanie')}
              cursor={'pointer'}
              marginRight={0}
              // tslint:disable-next-line:jsx-no-lambda
              onClick={() => setIsSearchVisible(!isSearchVisible)}
            />
          </MenuSearchWrapper>
        )}
        {isSearchVisible && (
          <SearchWrapper ref={searchWrapper}>
            <SearchForm
              searchResults={{
                productsResult: partialSearchProducts,
                categoriesResult: partialSearchCategories,
                articlesResult: [],
              }}
              partialSearchProductsIsFetching={partialSearchProductsIsFetching}
              partialSearchCategoriesIsFetching={
                partialSearchCategoriesIsFetching
              }
              redirectToSearchResultPage={redirectToSearchResultsPage}
              searchTerm={searchTerm}
              handleSearchTermChange={handleSearchTermChange}
              handleFocus={handleSearchFocus}
              hideResultViewer={hideSearchResultsViewer}
              resultsDropdownVisible={resultsDropdownVisible}
              isMobile={true}
              clearSearchInput={clearSearchInput}
              partialSearchArticlesIsFetching={false}
              showMoc={showMoc}
              showWithVat={showWithVat}
            />
          </SearchWrapper>
        )}
        {isMenuVisible && (
          <>
            <MobileNavMenu closeMenu={closeMenu}>
              <MobileNavigation
                closeMenu={closeMenu}
                items={items}
                landingPages={landingPages}
                lang={lang}
                navArticles={headerArticles}
                byId={byId}
                showActionLink={showActionLink}
                showGiftsCategory={showGiftsCategory}
              />
            </MobileNavMenu>
            <Dimmer height={100} zIndex={-1} />
          </>
        )}
      </HeaderWrapper>
    </StyledMobileWrapper>
  );
}

export default MobileHeader;
