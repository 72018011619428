import * as React from 'react';
import { __, __r } from 'react-i18n';
import styled from '../../theme/styled-components';
import { rem } from 'polished';
import {
  FlexCol,
  ZemplinTitle as Title,
  DsiDashboardColumn as DashboardColumn,
  DsiInfoText,
  DsiInfoType,
  DsiSvgIcon,
  DsiIconType,
} from 'eshop-defaults';
import API, { ThenArg } from '../../services/API';
import { FlexRow } from 'eshop-defaults/lib';
import { Link } from 'react-router';
import { prop } from '../../utilities';
import PaymentsBox from './PaymentsBox';
import PersonsBox from './PersonsBox';
import XmlBox from './XmlBox';

interface Props {
  invoices: any;
  orders: ThenArg<typeof API.getOrders>;
  ordersData: any;
  handleOffsetChange: (e) => void;
  currentId: string;
  options: { name: string; value: string }[];
  handleSelectChange: (e) => void;
  isFetchingOrders: boolean;
  user: any;
  lang: string;
  userData: any;
  userFinancialData?: any;
  currency: any;
}

function MyDashboard({
  currentId,
  orders,
  invoices,
  ordersData,
  handleOffsetChange,
  handleSelectChange,
  options,
  isFetchingOrders,
  user,
  lang,
  userData,
  userFinancialData,
  currency,
}: Props) {
  const content = [
    {
      content: [
        {
          name: __('Obrat za posledných 12 mesiacov'),
          number: 999900,
        },
        {
          name: __('Obrat za rok 2019'),
          number: 999900,
        },
      ],
      title: __('Faktúry'),
      link: __r('routes:moj-ucet/faktury', '/moj-ucet/faktury'),
    },
    // {
    //   content: [
    //     {
    //       name: __('Pridelený'),
    //       number: 999900,
    //     },
    //     {
    //       name: __('Čerpaný'),
    //       number: 999900,
    //     },
    //     {
    //       name: __('Zostáva'),
    //       number: 999900,
    //     },
    //   ],
    //   title: __('Kredit'),
    //   link: '',
    // },
    // {
    //   content: [
    //     {
    //       name: __('Celkom'),
    //       number: 1099800,
    //     },
    //     {
    //       name: __('Celkom po splatnosti'),
    //       number: 999900,
    //     },
    //   ],
    //   title: __('Záväzky'),
    //   link: __r('routes:moj-ucet/zavazky', '/moj-ucet/zavazky'),
    // },
    // {
    //   content: [
    //     {
    //       name: __('Celkom'),
    //       number: 1999800,
    //     },
    //   ],
    //   title: __('Backorder'),
    //   link: __r('routes:moj-ucet/backorder', '/moj-ucet/backorder'),
    // },
  ];
  const xmlUrl =
    user && user.b2b
      ? prop(user, 'parent.poznamka')
        ? `${process.env.REACT_APP_API_BASE_URL}/file?query=user-xml/${prop(
            user,
            'parent.poznamka',
          )}.xml`
        : user.poznamka
        ? `${process.env.REACT_APP_API_BASE_URL}/file?query=user-xml/${user.poznamka}.xml`
        : ''
      : '';

  const brandsXmlUrl = user
    ? `https://www.edsi.sk/api/v1/export/producers?lang=${user.lang}`
    : null;

  return (
    <Wrapper>
      <StyledTitle marginTop={0} marginBottom={32}>
        {__('Dashboard')}
      </StyledTitle>
      {/*
      <DsiInfoText
        type={DsiInfoType.INFO}
        //   info={`${__('Vitajte v novej verzii EDSI')},
        // ${__(
        //   'Napriek všetkému vynaloženému úsiliu, sa nám nepodarilo do nového systému preniesť všetko. Ak si chcete pozrieť históriu vašich nákupov či minulé faktúry a objednávky, starý systém je dostupný na',
        // )}  <a href="https://old.edsi.sk" target="_blank" data-saferedirecturl="https://www.google.com/url?q=https://old.edsi.sk&amp;source=gmail&amp;ust=1625117759997000&amp;usg=AFQjCNF2zik26cvr9dRlGlcFhW9uROn9gA">https://old.edsi.sk</a>
        // ${__(
        //   'Intenzívne pracujeme na doplnenie funkcionalít, ktoré sa budú postupne objavovať. Ak máte akýkoľvek problém s objednávkou, alebo faktúrou, určite Vám pomôžu naši distribuční pracovníci, alebo Váš obchodný zástupca',
        // )}.
        // ${__('Ďakujeme za pochopenie')}.`}
        info={
          <>
            <span>
              {`${__('Vitajte v novej verzii EDSI')},
          `}
            </span>
            <p>
              {`${__(
                'Napriek všetkému vynaloženému úsiliu, sa nám nepodarilo do nového systému preniesť všetko. Ak si chcete pozrieť históriu vašich nákupov či minulé faktúry a objednávky, starý systém je dostupný na',
              )}
          `}
              <StyledA
                target="__blank"
                rel="noreferrer"
                href={__('https://old.edsi.sk')}
              >{`${__('https://old.edsi.sk')}.`}</StyledA>
            </p>{' '}
            <p>
              {`${__(
                'Intenzívne pracujeme na doplnenie funkcionalít, ktoré sa budú postupne objavovať. Ak máte akýkoľvek problém s objednávkou, alebo faktúrou, určite Vám pomôžu naši distribuční pracovníci, alebo Váš obchodný zástupca',
              )}.
          `}
            </p>
            <p>{`${__('Ďakujeme za pochopenie')}.`}</p>
          </>
        }
      />
      */}

      <>
        <UpBoxWrapper>
          {userData && (
            <PaymentsBox
              userData={userData}
              userFinancialData={userFinancialData}
              currency={currency}
            />
          )}

          {userFinancialData && (
            <PersonsBox userFinancialData={userFinancialData} />
          )}

          {xmlUrl && (
            <XmlBox xmlUrl={xmlUrl} brandsXmlUrl={brandsXmlUrl} lang={lang} />
          )}
        </UpBoxWrapper>
      </>

      {/* <FlexRow>
        {content &&
          content.map((item, key) => (
            <DashboardColumn
              key={key}
              title={item.title}
              content={item.content}
              url={item.link}
            />
          ))}
      </FlexRow> */}
    </Wrapper>
  );
}

const UpBoxWrapper = styled.div`
  display: flex;
  gap: 12px;

  ${({ theme }) => theme.mediab.l925`
    flex-direction: column;
  `}
`;

const Wrapper = styled(FlexCol)`
  width: 100%;
  margin-right: 0;

  ${({ theme }) => theme.mediab.l925`
    padding-top: ${rem(32)};
  `}
`;

export const StyledTitle = styled(Title)`
  font-weight: 500;
  font-size: ${rem(36)};
`;

const XmlTitle = styled.h3`
  font-family: ${({ theme }) => theme.fonts.primary};
  font-size: ${rem(20)};
  font-weight: 500;
  margin-bottom: ${rem(24)};
`;

const XmlLink = styled.a`
  text-decoration: underline;
  width: fit-content;
  word-break: break-word;

  &:hover {
    opacity: 0.7;
  }
`;

const XmlManualWrapper = styled.a`
  display: flex;
  flex-flow: row;
  margin-top: ${rem(24)};
`;

const XmlManualText = styled.span`
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
  margin-left: ${rem(8)};
`;

const StyledA = styled.a`
  color: white;
  display: inline;
  font-weight: 500;
  text-decoration: underline;
`;

export default MyDashboard;
