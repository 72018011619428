import * as React from 'react';
import { connectSsr } from 'ssr-service';
import API, { ThenArg } from '../../services/API';
import { connect } from 'react-redux';
import {
  langSelector,
  contactInfoSelector,
  partialSearchProductsSelector,
  partialSearchProductsIsFetchingSelector,
  partialSearchCategoriesSelector,
  partialSearchCategoriesIsFetchingSelector,
  headerDataSelector,
  settingsSelector,
} from '../App/selectors';
import Header from '../../components/Header/Header';
import {
  categoryEntitiesSelector,
  landingPagesSelector,
  productCategoriesSelector,
} from './selectors';
import {
  cartCurrencySelector,
  cartDataSelector,
  cartPriceSelector,
} from '../Cart/cartSlice';

interface Props {
  user: any;
  lang: string;
  productCategories: ThenArg<typeof API.loadTree>;
  landingPages: any;
  dispatch: (action: any) => void;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  contact: {
    email: string;
    phone: string;
    saleB2B: string;
    saleB2C: string;
  };
  partialSearchProducts: ThenArg<typeof API.searchProducts>;
  partialSearchProductsIsFetching: boolean;
  partialSearchCategories: ThenArg<typeof API.loadCategories>;
  partialSearchCategoriesIsFetching: boolean;
  isHome: boolean;
  headerData: ThenArg<typeof API.loadSitemapSubtree>['sitemap_tree'];
  cartPrice: number;
  cartCurrency: string;
  byId: any;
  cartData: ThenArg<typeof API.getCart>;
  settings: any;
}

class ContainerHeader extends React.Component<Props> {
  public render() {
    const {
      socialUrls,
      lang,
      contact,
      dispatch,
      productCategories,
      landingPages,
      partialSearchProducts,
      partialSearchProductsIsFetching,
      partialSearchCategories,
      partialSearchCategoriesIsFetching,
      user,
      isHome,
      headerData,
      cartPrice,
      cartCurrency,
      byId,
      cartData,
      settings,
    } = this.props;

    const isB2B = user && user.b2b;

    return (
      <Header
        user={user}
        dispatch={dispatch}
        socialUrls={socialUrls}
        lang={lang}
        contact={contact}
        items={productCategories}
        landingPages={landingPages}
        partialSearchProducts={partialSearchProducts}
        partialSearchProductsIsFetching={partialSearchProductsIsFetching}
        partialSearchCategories={partialSearchCategories}
        partialSearchCategoriesIsFetching={partialSearchCategoriesIsFetching}
        isHome={isHome}
        headerData={headerData}
        cartPrice={cartPrice}
        cartCurrency={cartCurrency}
        byId={byId}
        cartData={cartData}
        showActionLink={
          isB2B ? contact.saleB2B === '1' : contact.saleB2C === '1'
        }
        showGiftsCategory={settings?.showGiftsCategory}
      />
    );
  }
}

const mapStateToProps = state => {
  return {
    lang: langSelector(state),
    contact: contactInfoSelector(state),
    socialUrls: state.general.socialUrls,
    productCategories: productCategoriesSelector(state),
    landingPages: landingPagesSelector(state),
    partialSearchProducts: partialSearchProductsSelector(state),
    partialSearchProductsIsFetching: partialSearchProductsIsFetchingSelector(
      state,
    ),
    partialSearchCategories: partialSearchCategoriesSelector(state),
    partialSearchCategoriesIsFetching: partialSearchCategoriesIsFetchingSelector(
      state,
    ),
    user: state.auth.user,
    headerData: headerDataSelector(state),
    cartPrice: cartPriceSelector(state),
    cartCurrency: cartCurrencySelector(state),
    byId: categoryEntitiesSelector(state),
    cartData: cartDataSelector(state),
    settings: settingsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'ContainerHeader' })(ContainerHeader),
);
