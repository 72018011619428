import * as React from 'react';
import styled from 'styled-components';
import { rem } from 'polished';
import { connect } from 'react-redux';
import { DsiInput, TextP } from 'eshop-defaults';
import {
  FlexRowCenterVertical,
  DsiSvgIcon as SvgIcon,
  ZemplinButton as Button,
} from 'eshop-defaults';
import { Modal } from '../_helpers/Modal/Modal';

import { IconType } from 'eshop-defaults/lib/components/Dsi/General/SvgIcon';
import { Link } from 'react-router';
import { __, __r } from 'react-i18n/lib';
import Dimmer from '../_helpers/Dimmer/Dimmer';
import OutsideClick from '../_helpers/Default/OutsideClick';
import {
  addToCartModalProductSelector,
  addToCartModalVisibleSelector,
  hideAddToCartModalVisibility,
  resetCart,
  setAddToCartModalVisibility,
  setWasAddedToCartModalVisibility,
  wasAddedToCartModalProductSelector,
  wasAddedToCartModalSelector,
} from '../../containers/Cart/cartSlice';
import { prop } from '../../utilities';
import { Loader } from '../_helpers/Loader/Loader';
import AccountSelectItem from './AccountSelectItem';
import API from '../../services/API';
import { logoutUser } from 'react-auth/lib/containers/Auth/actions';
import { removeCartCookie } from '../../containers/Cart/helpers';

interface Props {
  lang: string;
  dispatch: any;
  user: any;
}

function AccountSelectWindow({ dispatch, user, lang }: Props) {
  const closeModal = () => {
    dispatch(setAddToCartModalVisibility(false, null));
  };
  let loadResultsTimeout: any = React.useRef<any>(null);

  const [query, setQuery] = React.useState<any>(null);
  const [data, setData] = React.useState<any>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const baseTimeIntervalMs = 750;
  const extendedTimeIntervalMs = 1300;

  React.useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);

      const result = await API.loadCustomerRelations(user.id, {
        relation: 'CAN_IMPERSONATE',
        query: query ? query : undefined,
      });

      setData(prop(result, 'users'));
      setIsLoading(false);
    };

    loadResultsTimeout = setTimeout(
      async () => {
        await Promise.all([fetchData()]);
      },
      query && query.length <= 2 ? extendedTimeIntervalMs : baseTimeIntervalMs,
    );
  }, [query]);

  // if (!modalVisible) {
  //   return null;
  // }

  const handleQueryChange = e => {
    if (loadResultsTimeout) {
      clearTimeout(loadResultsTimeout);
    }

    setQuery(e.target.value);
  };

  React.useEffect(() => {
    document.body.style.overflow = 'hidden';

    return () => {
      document.body.style.overflow = 'visible';
    };
  }, []);

  const impersonate = async id => {
    const data: any = await API.impersonateUser({
      user: id,
      redirectUri: `${process.env.REACT_APP_BASE_URL?.replace(
        '.sk',
        `.${lang}${__r('routes:prihlasenie', '/prihlasenie')}`,
      )}`,
    });

    if (data && data.redirectUriFinal) {
      logoutRedux(dispatch).then(() => {
        if (window) {
          window.location.href = data.redirectUriFinal;
        }
      });
    }
  };

  const logoutRedux = dispatch => {
    return new Promise((resolve, reject) => {
      dispatch(logoutUser(true, () => dispatch(resetCart(false)), '/'));
      removeCartCookie();
      dispatch(resetCart(false));
      // dispatch(fetchCart());
      resolve(null);
    });
  };

  const handleLogoutUser = () => {
    logoutRedux(dispatch).then(() => {
      if (window) {
        window.location.reload();
      }
    });
  };

  return (
    <>
      <Dimmer topZero={true} height={100} zIndex={990} />
      <OutsideClick handleFunction={closeModal}>
        <WrapperModal>
          <Wrapper>
            <Heading>
              <Title>{__('Vyberte zákazníka')}</Title>
              {!!user && (
                <Logout onClick={handleLogoutUser}>{__('Odhlásiť sa')}</Logout>
              )}
            </Heading>
            <StyledInput
              onChange={handleQueryChange}
              placeholder={__('Hľadaný výraz...')}
            />
            {isLoading ? (
              <Loader />
            ) : (
              <ItemWrapper>
                {data &&
                  data.map(d => {
                    return (
                      <AccountSelectItem
                        onClick={() => impersonate(d.id)}
                        key={d.id}
                        name={d.meno}
                        login={d.login}
                        parent={d.parent}
                        isParent={!!!d.parent_user_id}
                        address={`${d.ulica || ''} ${d.cislo || ''} ${d.mesto}`}
                        outerId={d.outer_id}
                        cartLastUpdated={d.cart_last_updated}
                        cartTotalPriceWithoutVat={
                          d.cart_total_price_without_vat
                        }
                        cartCurrency={d.cart_currency}
                      />
                    );
                  })}
              </ItemWrapper>
            )}
          </Wrapper>
        </WrapperModal>
      </OutsideClick>
    </>
  );
}

const StyledModal = styled(Modal)`
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0px 1px 24px rgba(0, 0, 0, 0.08);
  border-radius: ${rem(4)};
`;

export const WrapperModal = styled(StyledModal)`
  /* max-width: ${rem(500)}; */
  width: 98%;
  height: 95%;
`;

const StyledInput = styled(DsiInput)`
  margin-top: 0;
  margin-bottom: ${rem(24)};
  max-width: ${rem(400)};
`;

const Wrapper = styled.div`
  width: 100%;
  padding: ${rem(32)} ${rem(24)} 0;
`;

const Title = styled(TextP)`
  font-size: ${rem(30)};
  font-weight: 400;
  width: 100%;
`;

const Heading = styled(FlexRowCenterVertical)`
  width: 100%;
  margin-bottom: ${rem(24)};
  justify-content: space-between;
`;

const IconWrapper = styled(FlexRowCenterVertical)`
  width: ${rem(30)};
  height: ${rem(30)};
  cursor: pointer;
`;

const Logout = styled(TextP)`
  font-size: ${rem(14)};
  color: ${({ theme }) => theme.colors.primary};
  width: ${rem(100)};
  text-align: right;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledSvgIcon = styled(SvgIcon)`
  margin: 0 auto;
  cursor: pointer;
`;

const ItemWrapper = styled.div`
  margin-bottom: ${rem(40)};
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;
  max-height: calc(100vh - 210px);
  height: 100%;
`;

const ProductImg = styled.img`
  margin-right: ${rem(8)};
`;

const Buttons = styled(FlexRowCenterVertical)`
  justify-content: space-between;
`;

const StyledButton = styled(Button)`
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.08);
  border: 2px solid rgba(30, 57, 141, 0.12);
  border-radius: 4px;
`;

const CloseButton = styled(StyledButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: ${({ theme }) => theme.fonts.primaryFont};
  background-color: ${({ theme }) => theme.colors.white};
`;

const Name = styled(TextP)`
  font-weight: 500;
`;

const mapStateToProps = state => {
  return {
    lang: state.general.lang,
    modalVisible: addToCartModalVisibleSelector(state),
    modalProduct: addToCartModalProductSelector(state),
  };
};

export default connect(mapStateToProps)(AccountSelectWindow);
