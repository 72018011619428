import { DsiInfoText, DsiInfoType, ZemplinLoaderWrapper } from 'eshop-defaults';
import * as React from 'react';
import styled from 'styled-components';
import * as cookie from 'react-cookies';
import { connect } from 'react-redux';
import { connectSsr } from 'ssr-service';
import {
  loadFooterData,
  loadTopMenuItems,
  setCurrency,
  loadDefaultSettings,
  setLanguage,
  resetToken,
  setHostname,
  loadSearchTermResults,
  loadOtherDefaultsTexts,
  loadPriceDisplaySettings,
} from './actions';
import { CURRENCY_COOKIE } from './constants';
import {
  dimmerVisibilitySelector,
  currentThemeSelector,
  langChangedSelector,
  isBreadcrumbVisibleSelector,
  ecomailSettingsSelector,
} from './selectors';
import Dimmer from '../../components/_helpers/Dimmer/Dimmer';
import { loadCategoriesTree, loadLandingPages } from '../Header/actions';
import Footer from '../Footer/Footer';
import { GlobalStyles } from '../../theme/globalStyles';
import { LogoMicrodata } from '@bart.sk-ecommerce/react-microdata';
import { theme } from '../../theme/theme';
import ContainerHeader from '../Header/Header';
import { rem } from 'polished';
import { USER_COOKIE } from 'react-auth';
import { loginUser } from 'react-auth/lib/containers/Auth/actions';
import { ThemeProvider } from '../../theme/styled-components';
import { loadHomeProductList2, loadHomeProductList1 } from '../Home/actions';
import BreadCrumbContainer from '../BreadCrumb/BreadCrumb';
import { fetchCart, resetCart } from '../Cart/cartSlice';
import {
  DEFAULT_CATEGORY_ID,
  extractBaseDomain,
  extractRustPart,
  resolveBaseUrlByLang,
  resolveCurrencyByHostname,
  resolveLangByHostname,
} from '../../configureTrans';
import AppLanguageRedirectChecker from '../../components/Home/AppLanguageRedirectChecker';
import AddToModalWindow from '../../components/Product/AddToModalWindow';
import AddToCartModalWindow from '../../components/Product/AddToCartModalWindow';
import AccountSelectWindow from '../../components/MyAccount/AccountSelectWindow';
import NewWebModal from '../../components/Home/NewWebModal';
import PriceUpdateInfo from '../MyAccount/PriceUpdateInfo';
import { resolveUserArePricesLoading } from '../../utilities/user';
import MetaTags from '../../components/_helpers/MetaTags/MetaTags';
import {
  checksRedirect,
  isSSR,
  setRedirect,
} from '@bart.sk-ecommerce/react-eshop-redirects/lib/action';
import { prop } from '../../utilities';
import { loadCategoryProducts } from '../Category/actions';
import { urlAttribsToObject } from '../Category/categorySlice';
import { reloadCurrentProduct } from '../Product/actions';
import CookieBot from 'react-cookiebot/lib/CookieBot';
import { __ } from 'react-i18n';
import { CART_COOKIE_ID, saveCartCookie } from '../Cart/helpers';
import { RESET_CART } from 'react-auth/lib/containers/Auth/constants';
import API from '../../services/API';
import SearchResults from '../Search/SearchResults';
import { TopInformationStrip } from '../../components/Header/TopInformationStrip';
import { AdminBanner } from 'eshop-defaults';
import { AdminBannerActivate } from 'eshop-defaults';
import { Helmet } from 'react-helmet';
import CookieConsent from '../../components/_helpers/CookieConsent/CookieConsent';

const domainGroupId = process.env.REACT_APP_COOKIEBOT_DOMAIN_ID;
interface Props {
  user: any;
  lang: string;
  props: any;
  socialUrls: {
    fbUrl: string;
    igUrl: string;
    ytUrl: string;
  };
  metaTags: {
    description: string;
    keywords: string;
    og_article_author: string;
  };
  dispatch: any;
  isError: boolean;
  dimmerVisible: boolean;
  currentTheme: string;
  isLoaded: boolean;
  token: string;
  langChanged: boolean;
  location: any;
  isBreadcrumbVisible: boolean;
  cookies: any;
  otherTexts: any;
  brandId: number;
  brandName: number;
  hostname: string;
  adminContentPath: string;
  ecomailSettings: { customScript: string };
}

export const FillSpaceWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  min-height: ${rem(600)};

  ${() => theme.mediab.l925`
     min-height: ${rem(400)};
  `}
`;

class App extends React.Component<Props> {
  public static async getInitialProps(props) {
    const { dispatch, token, user, forceReload, cookies, lang } = props;

    try {
      let brandId: any = null;
      let brandName: any = null;

      await dispatch(checksRedirect(props.location));
      console.log(resolveLangByHostname(props.hostname));

      if (!forceReload) {
        await Promise.all[
          (dispatch(setLanguage(resolveLangByHostname(props.hostname))),
          dispatch(setCurrency(resolveCurrencyByHostname(props.hostname))),
          dispatch(setHostname(props.hostname)))
        ];
      }

      const rustPart = extractRustPart(props.hostname);
      /*
      if (rustPart) {
        const brandsResponse = await API.loadBrands({ name: rustPart });
        if (
          brandsResponse &&
          brandsResponse.brands &&
          brandsResponse.brands.length
        ) {
          const brand = brandsResponse.brands[0];
          brandId = brand.brand_id;
          brandName = brand.brand_name;
        } else {
          dispatch(
            setRedirect('http://' + extractBaseDomain(props.hostname), 301),
          );
          if (window) {
            window.location.replace(
              'http://' + extractBaseDomain(props.hostname),
            );
          }
        }
      }
      */

      const userCookie =
        prop(cookies, `${USER_COOKIE}`) || !isSSR()
          ? cookie.load(USER_COOKIE)
          : null;
      await dispatch(
        loginUser(userCookie, false, () => {
          dispatch(resetCart(false));
          if (window && window.location && window.location.reload) {
            window.location.reload();
          }
        }),
      );

      await dispatch(loadPriceDisplaySettings());

      if (!token || !user) {
        if (userCookie) {
          await dispatch(
            loginUser(userCookie, false, () => {
              dispatch(resetCart(false));
              if (window && window.location && window.location.reload) {
                window.location.reload();
              }
            }),
          );
        } else {
          await dispatch(resetToken());
        }
        // if (process.env.NODE_ENV === 'development') {
        //   await dispatch(
        //     loginUser(
        //       '07baf36106fe7aa725871dcdea11b74e11277def15411f7a81b5610ccccb6fb6f31f34b523c3667f8433e9dd4f1b5fb413dab3e47ff91e4b21430ce5d9776627785e8762dc9cee2ab4d1e3a128c7b43157131e2f793e6cb9326fc564bc6002e4fecda7801e84df17ca1f3de9f30b5c53ea6b651af5ea840a439dfad3183a51ac',
        //     ),
        //   );
        // }
      }
      await dispatch(loadDefaultSettings());

      if (props.forceReload) {
        if (prop(props, 'params.category_id')) {
          await dispatch(
            loadCategoryProducts(
              prop(props, 'params.category_id'),
              urlAttribsToObject(prop(props, 'location.query')),
            ),
          );
        } else if (
          (prop(props, 'location.pathname') || '').includes('/vyhladavanie') ||
          (prop(props, 'location.pathname') || '').includes('/vyhledavani')
        ) {
          await dispatch(
            loadSearchTermResults(
              prop(props, 'location.query.q'),
              20,
              prop(props, 'location.query.offset') || 0,
            ),
          );
        } else {
          await dispatch(reloadCurrentProduct());
        }
      }
      let cartCookie = prop(cookies, `${CART_COOKIE_ID}`);
      // let cartCookie = '6fb8ba85-954b-4c68-a29c-8b3ecf017d0d';
      try {
        if (userCookie && cartCookie) {
          const tokenInfo = await API.tokeninfo({
            accessToken: userCookie,
            lang,
          });
          if (!tokenInfo || !tokenInfo.email) {
            cartCookie = 'a';
            saveCartCookie(cartCookie);
          }
        }
      } catch (e) {
        cartCookie = 'a';
        saveCartCookie(cartCookie);
      }
      if (!forceReload) {
        const firstCategoryId =
          DEFAULT_CATEGORY_ID[extractBaseDomain(props.hostname)][lang];

        await Promise.all([
          dispatch(loadCategoriesTree()),
          dispatch(loadLandingPages()),
          dispatch(loadHomeProductList1(firstCategoryId)),
          dispatch(loadHomeProductList2(firstCategoryId)),
          dispatch(loadFooterData()),
          dispatch(loadTopMenuItems()),
          dispatch(fetchCart(true, cartCookie, true)),
          dispatch(loadOtherDefaultsTexts()),
        ]);
      }

      return {
        props,
        isLoaded: true,
        brandId: brandId,
        brandName: brandName,
        hostname: props.hostname,
      };
    } catch (exp) {
      // console.log(exp);
      return {
        isError: true,
        isLoaded: true,
      };
    }
  }

  public render() {
    const {
      children,
      dimmerVisible,
      socialUrls,
      currentTheme,
      isLoaded,
      langChanged,
      lang,
      location,
      dispatch,
      user,
      isBreadcrumbVisible,
      otherTexts,
      brandId,
      brandName,
      adminContentPath,
      cookies,
      ecomailSettings,
    } = this.props;
    const currentLocation = location.pathname;

    const currentThemeColors =
      !currentTheme || !theme[currentTheme]
        ? {
            primary: theme.colors.primary,
            navSecondary: theme.colors.navSecondary,
            paginationSecondary: theme.colors.paginationSecondary,
          }
        : {
            primary: theme[currentTheme].primary,
            navSecondary: theme[currentTheme].navSecondary,
            paginationSecondary: theme[currentTheme].paginationSecondary,
          };

    let isHome = false;
    // @ts-ignore
    if (children !== null && 'props' in children) {
      isHome =
        children.props.location.pathname === '/' &&
        (!this.props.hostname || extractRustPart(this.props.hostname) === null);
    }

    const baseUrl = resolveBaseUrlByLang(lang);

    return (
      <React.Fragment>
        {!isSSR() ? (
          <CookieConsent
            domainGroupId={domainGroupId}
            lang={lang === 'cz' ? 'Cs' : 'SK'}
          />
        ) : (
          <></>
        )}
        <GlobalStyles />
        <LogoMicrodata
          url={'https://www.edsi.sk'}
          logo={'https://www.edsi.sk/images/icons/logo.svg'}
        />

        {ecomailSettings?.customScript ? (
          <Helmet>
            <script>{decodeURIComponent(ecomailSettings.customScript)}</script>
          </Helmet>
        ) : (
          <></>
        )}

        <ThemeProvider
          theme={{
            ...theme,
            colors: {
              ...theme.colors,
              ...currentThemeColors,
            },
          }}
        >
          {this.props.metaTags && (
            <MetaTags
              tags={{
                ...this.props.metaTags,
                og_url: `${baseUrl}${location.pathname}`,
                themeColor: currentThemeColors.primary,
              }}
            />
          )}

          {this.props?.location?.search?.includes('activate-admin-banner') ? (
            <AdminBannerActivate />
          ) : (
            <></>
          )}

          <AdminBanner
            adminDomainUrl={process.env.REACT_APP_ADMIN_URL as string}
            contentPath={adminContentPath}
          />

          {user && user.b2b && user.originalUser ? (
            <DsiInfoText
              type={DsiInfoType.SUCCESS}
              info={`${__('Nakupujete ako')}: ${user.name} (${user.outer_id ||
                ''}). Prihlásený: ${user.originalUser.meno} ${
                user.originalUser.priezvisko
              }`}
              marginBottom={0}
              isDismissable={false}
              onClick={() => {}}
            />
          ) : null}
          {user && user.b2b ? <PriceUpdateInfo dispatch={dispatch} /> : null}
          <AppLanguageRedirectChecker
            lang={lang}
            langChanged={langChanged}
            currentLocation={currentLocation}
          />
          {otherTexts?.TOP_INFORMATION_STRIP?.body ? (
            <TopInformationStrip
              text={otherTexts?.TOP_INFORMATION_STRIP?.body}
            ></TopInformationStrip>
          ) : (
            <></>
          )}
          <ContainerHeader isHome={isHome} />
          {isBreadcrumbVisible && !isHome && <BreadCrumbContainer />}
          <FillSpaceWrapper>
            {!isLoaded ? <ZemplinLoaderWrapper height={300} /> : children}
          </FillSpaceWrapper>
          <Footer socialUrls={socialUrls} />
          <AddToModalWindow dispatch={dispatch} />
          <AddToCartModalWindow dispatch={dispatch} />
          {user && user.customerType === 'PERSONAL' ? (
            <AccountSelectWindow dispatch={dispatch} user={user} lang={lang} />
          ) : null}
          {dimmerVisible && <Dimmer height={null} />}
        </ThemeProvider>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    socialUrls: state.general.socialUrls,
    metaTags: state.general.metaTags,
    lang: state.general.lang,
    dimmerVisible: dimmerVisibilitySelector(state),
    currentTheme: currentThemeSelector(state),
    langChanged: langChangedSelector(state),
    isBreadcrumbVisible: isBreadcrumbVisibleSelector(state),
    otherTexts: state.general.otherTexts,
    adminContentPath: state.general.adminContentPath,
    ecomailSettings: ecomailSettingsSelector(state),
  };
};

export default connect(mapStateToProps)(
  connectSsr({ displayName: 'App' })(App),
);
