import React, { memo } from 'react';
import CookieBot from 'react-cookiebot/lib/CookieBot';

interface CookieConsentProps {
  domainGroupId: string | undefined;
  lang: string;
}

const CookieConsent = memo(({ domainGroupId, lang }: CookieConsentProps) => {
  return (
    <CookieBot
      domainGroupId={domainGroupId}
      language={lang === 'cz' ? 'Cs' : 'SK'}
    />
  );
});

export default CookieConsent;
